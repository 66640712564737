import React, { useState, Fragment } from 'react';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { navigation } from "../Navigation/Navigation/navigation"; // Adjust the import path accordingly

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Mobile Menu */}
      <Transition.Root show={openMenu} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpenMenu}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4 py-4">
                  <h2 className="text-lg font-semibold">Menu</h2>
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpenMenu(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Navigation Links */}
                <div className="mt-6">
                  {navigation.mobilepages.map((page) => (
                    <a
                      key={page.name}
                      href={page.href}
                      className="block px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-100"
                      onClick={() => setOpenMenu(false)} // Close menu on link click
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Header Section */}
      <header className="bg-yellow-500 w-full h-16 lg:h-24 mb-8 flex items-center justify-between px-4 lg:px-14 shadow-md">
        {/* Hamburger Menu (Mobile Only) */}
        <div className="flex lg:hidden">
          <button
            onClick={() => setOpenMenu(true)}
            className="text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <Bars3Icon className="h-6 w-6" />
            <span className="sr-only">Open menu</span>
          </button>
        </div>

        {/* Back Arrow (Desktop Only) */}
        <div className="hidden lg:flex items-center">
          <button
            onClick={() => navigate("/")}
            className="flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: { xs: 30, sm: 40, md: 50, lg: 60 } }}
              className="text-black mr-2"
            />
            <span className="sr-only">Back</span>
          </button>
        </div>

        {/* Page Title */}
        <span className="text-black font-extrabold text-2xl lg:text-5xl tracking-wider absolute left-1/2 transform -translate-x-1/2">
          PRIVACY POLICY
        </span>

        {/* Placeholder for Alignment */}
        <div className="hidden lg:block" style={{ width: "24px" }}></div>
      </header>

      {/* Privacy Policy Content */}
      <main className="flex-1 bg-gray-100 px-6 lg:px-16 py-8 text-black space-y-6">
        {/* Introduction */}
        <section>
          <h2 className="text-lg lg:text-2xl font-bold">Introduction</h2>
          <div className='text-sm lg:text-lg'>
            <p>
              This privacy policy (“Policy”) sets out how IITIAN Vibes (“IITIAN Vibes”, also identified by “we” or “us” or “our”) uses and protects any information that you provide when you use this website or mobile application (individually and collectively, “Platform”).
            </p>
            <p>
              IITIAN Vibes is committed to ensuring that your privacy is protected. Should we ask you to provide any information by which you can be identified when using this Platform, be assured that it will only be used strictly in accordance with this Policy.
            </p>
            <p>
              Please note that our Policy is subject to change at any time, and we may not be able to provide prior notice. To stay informed of updates, please review this page periodically. This Policy applies to current and former visitors and customers of our Platform. By visiting and/or using our Platform, you accept and consent to the practices described in this Policy.
            </p>
          </div>
        </section>

        {/* Information Collection */}
        <section>
          <h2 className="text-lg lg:text-2xl font-bold mt-8">What Information Do We Collect?</h2>
          <div className='text-sm lg:text-lg'>
            <p>
              We collect, store, and use your personal information (that we consider necessary) when you use our Platform. We use this information to provide you with a safe, efficient, and customized experience, which includes, but is not limited to, displaying content such as recommended products and communicating with you about your orders, new products, and the latest promotional offers. This enables us to provide specific services and features tailored to your needs, customize our Platform to make your experience safer and easier, and allow third parties to perform technical, logistical, and other functions on our behalf.
            </p>
          </div>
        </section>

        {/* Types of Information */}
        <section>
          <h2 className="text-lg lg:text-2xl font-bold mt-8">Types of Information We Gather:</h2>
          <div className='text-sm lg:text-lg'>
            <p>
              <strong>Information You Provide Us:</strong> We receive and store the information you explicitly provide to us, including personal details such as your user name and password, name, address, telephone number, email address, and other contact information, date of birth, gender, and financial details such as bank account or payment instrument information. You may choose not to provide certain information, but this could prevent you from taking full advantage of our Platform’s features.
            </p>
          </div>
        </section>

        {/* Information Sharing */}
        <section>
          <h2 className="text-lg lg:text-2xl font-bold mt-8">Do We Share the Information We Receive?</h2>
          <div className='text-sm lg:text-lg'>
            <p>
              Respecting customer privacy is a core philosophy at IITIAN Vibes. We never have and never will commercially exploit customer information by selling it to third parties. We share customer information only as described below:
            </p>
            <p>
              <strong>Third-Party Service Providers:</strong> We employ other companies and individuals to perform functions on our behalf, such as fulfilling orders, delivering packages, analyzing customer data, providing marketing assistance, and processing payments. They have access only to the information needed to perform these functions and are contractually bound to use it only for these purposes.
            </p>
            <p>
              <strong>Business Transfers:</strong> As we develop our business, we may sell or buy subsidiaries or business units. In such transactions, all information, including personal information, may be disclosed and transferred to an acquirer, successor, or assignee as part of any merger, acquisition, or other transaction.
            </p>
            <p>
              <strong>With Your Consent:</strong> Other than as set out above, you will receive notice when information about you might be shared with third parties, giving you the option to choose whether or not you’d like such information shared.
            </p>
          </div>
        </section>

        {/* Security */}
        <section>
          <h2 className="text-lg lg:text-2xl font-bold mt-8">Is Your Information Secure?</h2>
          <div className='text-sm lg:text-lg'>
            <p>
              Absolutely. The security of your information is a priority for us. We use appropriate security policies, rules, and technical measures, as required by applicable law, to protect your information from unauthorized access, improper use or disclosure, and unlawful destruction.
            </p>
            <p>
              However, IITIAN Vibes is not responsible for any breach of security or actions of third parties that receive users’ personal data or events beyond our control, such as hacking or unauthorized access.
            </p>
          </div>
        </section>

        {/* User Choices */}
        <section>
          <h2 className="text-lg lg:text-2xl font-bold mt-8">What Choices Do You Have?</h2>
          <div className='text-sm lg:text-lg'>
            <p>
              You can regularly add, correct, update, or review your personal information. However, we may keep a copy of previous versions for our records.
            </p>
            <p>
              If you do not want to receive emails or messages from us, please contact our customer care team to unsubscribe.
            </p>
            <p>
              You can choose not to provide your personal information, withdraw your consent for usage, or request deletion of your information. However, this may limit your ability to fully utilize the Platform’s features.
            </p>
            <p>
              You can delete your account by visiting the 'My Account' section on the app. However, this may limit your use of the Platform’s features.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
