import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Nav from "../customer/components/Navigation/Navigation/Nav";
import HomePage from "../customer/Pages/HomePage/HomePage";
import Cart from "../customer/components/Cart.jsx/Cart";
import Footer from "../customer/components/Footer/Footer";
import Product from "../customer/components/Product/Product";
import ProductDetails from "../customer/components/ProductDetails/ProductDetails";
import Checkout from "../customer/components/Checkout/Checkout";
import LandingPage from "../customer/components/LandingPage/LandingPage";
import About from "../customer/components/About/About";
import Contact from "../customer/components/Contact/Contact";
import NoRefundPolicy from "../customer/components/NoRefundPolicy/NoRefundPolicy";
import PrivacyPolicy from "../customer/components/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "../customer/components/TermsAndConditions/TermsAndCondition";
import Order from "../customer/components/Order/Order";
import OrderDeatils from "../customer/components/Order/OrderDeatils";
import PaymentSuccess from "../customer/components/Payment/PaymentSuccess";
import Profile from "../customer/components/Profile/Profile";

const CustomerRouters = () => {
  const location = useLocation();

  return (
    <div>
      <div>
        <Routes>
          {window.innerWidth < 768 ? (
            <Route path="/" element={<HomePage />}></Route>
          ) : (
            <Route path="/" element={<LandingPage />}></Route>
          )}
          <Route path="/login" element={<LandingPage />}></Route>
          <Route path="/register" element={<LandingPage />}></Route>
          <Route path="/collection" element={<HomePage />}></Route>
          <Route path="/cart" element={<Cart />}></Route>
          <Route path="/account/order" element={<Order />} />
          <Route path="/account/order/:orderId" element={<OrderDeatils />} />
          <Route path="/payment/:orderId" element={<PaymentSuccess />} />
          <Route path="/profile/user/:userId" element={<Profile />} />
          <Route
            path="/terms&conditions"
            element={<TermsAndCondition />}
          ></Route>
          <Route path="/refundPolicy" element={<NoRefundPolicy />}></Route>
          <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/aboutUs" element={<About />}></Route>
          <Route path="/contactUs" element={<Contact />}></Route>
          <Route path="/checkout" element={<Checkout />}></Route>
        </Routes>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default CustomerRouters;
