import axios from "axios";

export const API_BASE_URL = 
  "https://imaginative-manifestation-production.up.railway.app";
// export const API_BASE_URL = 
const jwt = localStorage.getItem("jwt");

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${jwt}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
