import React, { useState, Fragment } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { navigation } from "../Navigation/Navigation/navigation"; // Adjust the import path accordingly

const Contact = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Mobile menu */}
      <Transition.Root show={openMenu} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setOpenMenu}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpenMenu(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {navigation.mobilepages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Header Section */}
      <header className="bg-yellow-500 w-full h-20 lg:h-32 mb-12 flex items-center justify-between px-4 lg:px-14 shadow-md">
        {/* Back Button (Visible on large screens) */}
        <div className="hidden lg:flex">
          <button
            onClick={() => navigate("/")}
            className="flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: { xs: 30, sm: 40, md: 50, lg: 60 } }}
              className="text-black mr-2"
            />
            <span className="sr-only">Back</span>
          </button>
        </div>

        {/* Hamburger Menu in Mobile View */}
        <div className="flex lg:hidden">
          <button
            onClick={() => setOpenMenu(true)}
            className="text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded"
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
        </div>

        {/* Page Title (Centered) */}
        <span className="text-black font-extrabold text-2xl lg:text-5xl tracking-wider absolute left-1/2 transform -translate-x-1/2">
          CONTACT US
        </span>

        {/* Placeholder for Desktop View Alignment */}
        <div className="hidden lg:block" style={{ width: "24px" }}></div>
      </header>

      {/* Contact Details Section */}
      <main className="flex-1 bg-gray-100 px-4 sm:px-8 lg:px-16 py-8">
        <div className="max-w-7xl mx-auto">
          {/* Responsive Grid for Contact Cards */}
          <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1">
            {/* Contact Card for Anurag */}
            <div className="bg-gray-200 p-6 rounded-md shadow-md hover:shadow-lg transition-shadow duration-300">
              <h2 className="text-xl lg:text-2xl font-bold mb-4 text-gray-800">
                Anurag Kumar Bharti
              </h2>
              <div className="space-y-2 text-sm lg:text-base">
                <p>
                  <span className="font-semibold text-[#76520E]">Email:</span>{" "}
                  <a
                    href="mailto:anuragkumar.bh@gmail.com"
                    className="text-blue-700 hover:underline"
                  >
                    anuragkumar.bh@gmail.com
                  </a>
                </p>
                <p>
                  <span className="font-semibold text-[#76520E]">Phone:</span>{" "}
                  <span className="text-gray-700">+91-6261718764</span>
                </p>
                <p>
                  <span className="font-semibold text-[#76520E]">
                    LinkedIn:
                  </span>{" "}
                  <a
                    href="https://www.linkedin.com/in/anurag-bharti-502b44222"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </p>
                <p>
                  <span className="font-semibold text-[#76520E]">
                    Instagram:
                  </span>{" "}
                  <a
                    href="https://www.instagram.com/anuragkumar.bh/"
                    className="text-pink-700 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </p>
              </div>
            </div>

            {/* Contact Card for Arpit */}
            <div className="bg-gray-200 p-6 rounded-md shadow-md hover:shadow-lg transition-shadow duration-300">
              <h2 className="text-xl lg:text-2xl font-bold mb-4 text-gray-800">
                Arpit Arya
              </h2>
              <div className="space-y-2 text-sm lg:text-base">
                <p>
                  <span className="font-semibold text-[#76520E]">Email:</span>{" "}
                  <a
                    href="mailto:arya.8official@gmail.com"
                    className="text-blue-700 hover:underline"
                  >
                    arya.8official@gmail.com
                  </a>
                </p>
                <p>
                  <span className="font-semibold text-[#76520E]">Phone:</span>{" "}
                  <span className="text-gray-700">+91-7489467045</span>
                </p>
                <p>
                  <span className="font-semibold text-[#76520E]">
                    LinkedIn:
                  </span>{" "}
                  <a
                    href="https://www.linkedin.com/in/arpit-arya-49325a230"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </p>
              </div>
            </div>

            {/* Contact Card for Arun */}
            <div className="bg-gray-200 p-6 rounded-md shadow-md hover:shadow-lg transition-shadow duration-300">
              <h2 className="text-xl lg:text-2xl font-bold mb-4 text-gray-800">
                Arun Raghav S
              </h2>
              <div className="space-y-2 text-sm lg:text-base">
                <p>
                  <span className="font-semibold text-[#76520E]">Email:</span>{" "}
                  <a
                    href="mailto:arunaghavssreeragam@gmail.com"
                    className="text-blue-700 hover:underline"
                  >
                    arunraghavssreeragam@gmail.com
                  </a>
                </p>
                <p>
                  <span className="font-semibold text-[#76520E]">Phone:</span>{" "}
                  <span className="text-gray-700">+91-8281840462</span>
                </p>
                <p>
                  <span className="font-semibold text-[#76520E]">
                    LinkedIn:
                  </span>{" "}
                  <a
                    href="https://in.linkedin.com/in/arun-raghav-s-9518ba183"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </p>
                <p>
                  <span className="font-semibold text-[#76520E]">
                    Instagram:
                  </span>{" "}
                  <a
                    href="https://www.instagram.com/raghav_ettan"
                    className="text-pink-700 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </p>
              </div>
            </div>
            <div className="bg-gray-200 p-6 rounded-md shadow-md hover:shadow-lg transition-shadow duration-300">
              <h2 className="text-xl lg:text-2xl font-bold mb-4 text-gray-800">
                Business Address
              </h2>
              <div>Calicut, Kerala, 673017</div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Contact;
